<template>
  <!--begin::Project Listing-->
  <ListingTemplate :customClass="'visit-timesheet-listing'">
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <template v-if="selectedRows.length > 0">
            <v-col cols="4" class="pt-0">
              <h1 class="form-title d-flex margin-auto selected-rows-text">
                {{ totalSelected }} Selected
              </h1>
            </v-col>
            <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
              <template v-if="getPermission('project:update')">
                <v-menu
                  content-class="custom-menu-list"
                  bottom
                  offset-y
                  transition="slide-y-transition"
                  :close-on-click="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-grey-border custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(more, index) in projectMoreAction">
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="red lighten-1 white--text"
                v-on:click="clearSelections"
              >
                <v-icon dark left>mdi-close</v-icon>Clear Selections
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="pt-0 col-md-12 col-lg-6">
              <div
                class="d-flex align-itms-center"
                style="margin-left: -10px !important"
              >
                <div class="text-capitalize">
                  <h5 class="font-weigh-500" style="color: #3f4254 !important">
                    Total Hours :
                  </h5>
                </div>
                <div class="number cyan--text ms-2">
                  <h5 class="font-weigh-500" style="color: red !important">
                    {{ totalAmount(rowData) }}
                  </h5>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 col-md-12 col-lg-6 justify-flex-end d-flex margin-auto all-project-btn-right"
            >
              <v-flex
                md="2"
                class="pb-2 mr-2 d-flex"
                style="max-width: 100% !important"
              >
                <label for="" class="font-weight-600 mr-2"> Filter By </label>
                <v-autocomplete
                  hide-details
                  clearable
                  class="mr-2"
                  v-model.trim="filter_engineer"
                  :items="engineerList"
                  dense
                  flat
                  filled
                  label="Technician"
                  item-color="cyan"
                  color="cyan"
                  solo
                  v-on:click:clear="clearFilter('engineer')"
                  :disabled="pageLoading || engineerLoading"
                  :loading="engineerLoading || pageLoading"
                  item-text="display_name"
                  item-value="id"
                  v-on:change="getRows"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Technician(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      height="30"
                      min-width="30"
                      width="30"
                      class="mr-1 my-1 grey lighten-2 grey--text"
                    >
                      <v-img
                        v-if="item.profile_logo != null"
                        src="item.profile_logo"
                      ></v-img>
                      <span v-else class="fw-600 mb-1">{{
                        item.display_name.slice(0, 2).toUpperCase()
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-ellipsis max-width-400px">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.display_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      class="align-self-center"
                      v-if="filter_engineer == item.user"
                    >
                      <v-icon color="cyan">mdi-check</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Date Range"
                      hide-details
                      solo
                      :loading="pageLoading"
                      :disabled="pageLoading"
                      clearable
                      prepend-inner-icon="mdi-calendar"
                      v-on:click:clear="clearFilter('dates')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedDate"
                      color="cyan"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="cyan"
                    range
                    :disabled="pageLoading"
                    v-on:change="getRows"
                    v-model="dates"
                    :month-picker="true"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-bold-button"
                color="cyan white--text"
                v-on:click="exportVisitTimesheet"
              >
                <v-icon dark left>mdi-database-export</v-icon> Export
              </v-btn>
              <v-menu
                max-height="400"
                max-width="250"
                left
                offset-y
                :close-on-content-click="false"
                content-class="white-background"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button btn-tab-hide"
                    color="cyan white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <draggable
                  tag="ul"
                  v-model="defaultColDefs"
                  class="draggable-group"
                  handle=".draggable-drag-icon"
                  v-on:change="updateVisible()"
                >
                  <template v-for="cols in defaultColDefs">
                    <li
                      class="draggable-group-item"
                      :key="cols.field"
                      v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                    >
                      <v-checkbox
                        dense
                        v-model="defaultColShow"
                        v-bind:value="cols.field"
                        :label="cols.headerName"
                        :disabled="
                          (Number(cols.fixed) > 0 ? true : false) || dataLoading
                        "
                        color="cyan"
                        hide-details
                        class="mt-0 mb-0"
                        v-on:change="updateVisible()"
                      ></v-checkbox>
                      <v-icon class="draggable-drag-icon" right color="cyan"
                        >mdi-drag</v-icon
                      >
                    </li>
                  </template>
                </draggable>
              </v-menu>
            </v-col>
          </template>
        </template>
      </ListingHeader>
      <ListingSearchCriteria
        v-if="searchEnabled"
        pageModule="timeSheet"
        :basicSearchFields="['display_name']"
        :allowedSearchFields="searchableArray"
      ></ListingSearchCriteria>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <ListingTable
          :columnCount="columnLength()"
          :dataLoading="dataLoading"
          :rowData="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in customThead">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <template
                      v-if="cols.checkbox && Number(cols.checkbox) == 1"
                    >
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort,
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr
                  v-for="(data, index) in rowData"
                  :key="index"
                  link
                  class="timesheet-listing-row"
                >
                  <template v-for="cols in customThead">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.field == 'visit_barcode'">
                        <Barcode
                          :id="data.visit.id"
                          :barcode="data && data.visit && data.visit.barcode"
                          route="visit.detail"
                        ></Barcode>
                      </template>
                      <template v-else-if="cols.field == 'engineer_barcode'">
                        <Barcode
                          :id="data.engineer.id"
                          :barcode="
                            data && data.engineer && data.engineer.barcode
                          "
                          route="engineer.detail"
                        ></Barcode>
                      </template>
                      <template v-else-if="cols.field == 'engineer'">
                        <div class="engineer-listing">
                          <p class="m-0 custom-nowrap-ellipsis text-capitalize">
                            <b>Display name: </b>
                            {{
                              data &&
                              data.engineer &&
                              data.engineer.display_name
                            }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis text-capitalize">
                            <b>Designation: </b>
                            <v-chip
                              v-if="
                                data &&
                                data.engineer &&
                                data.engineer.designation &&
                                data.engineer.designation.text
                              "
                              small
                              text-color=""
                              color="red"
                              label
                              outlined
                            >
                              {{ data.engineer.designation.text }}
                            </v-chip>
                            <em class="text-muted" v-else> no designation</em>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'checkin_date'">
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <b>{{ formatDateCustom(data.checkin_time) }}</b>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'checkin_time'">
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <v-chip
                              small
                              v-if="data && data.checkin_time"
                              text-color=""
                              color="green"
                              label
                              outlined
                            >
                              {{ formatTimeCustom(data.checkin_time) }}
                            </v-chip>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'checkout_date'">
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <b>{{ formatDateCustom(data.checkout_time) }}</b>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'checkout_time'">
                        <div class="project-listing-project">
                          <v-chip
                            v-if="data && data.checkout_time"
                            small
                            text-color=""
                            color="red"
                            label
                            outlined
                          >
                            {{ formatTimeCustom(data.checkout_time) }}
                          </v-chip>
                          <b v-else> -- </b>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'total_time'">
                        <div class="project-listing-status">
                          <v-chip
                            small
                            text-color=""
                            v-if="data && data.checkout_time"
                            color="blue"
                            label
                            outlined
                          >
                            {{ data.total_time }}
                          </v-chip>
                          <b v-else> -- </b>
                        </div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td colspan="8" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no timesheet at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
      </perfect-scrollbar>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
      <Dialog :commonDialog="searchDialog">
        <template v-slot:title>Filter Project</template>
        <template v-slot:body>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 90vh; position: relative"
          >
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Project')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </perfect-scrollbar>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="dataLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            v-on:click="filterRows"
          >
            Filter
          </v-btn>
          <v-btn
            :disabled="dataLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="searchDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog="customerDialog"
          v-on:closeDialog="customerDialog = false"
          v-on:resetAll="customerDialog = false"
          v-on:selectCustomer="selectCustomer"
        ></CustomerDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </ListingTemplate>

  <!--end::Project Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import JwtService from "@/core/services/jwt.service";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
/* import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue" */ import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import moment from "moment-timezone";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "visit-timesheet-list",
  data() {
    return {
      pageTips: false,
      customerDialog: false,
      engineerList: [],
      customerPropertyDialog: false,
      exportLoading: false,
      pageModule: "visit-timesheet-listing",
      routeAPI: "visit-timesheet",
      routeName: "profile.visit-timesheet",
      status: "all",
      engineerLoading: false,
      pageLoading: false,
      file: "",
      importDialog: false,
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      projectMoreAction: [
        {
          title: "Mark as Finished",
          action: "mark_as_finish",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Cancelled",
          action: "mark_as_cancel",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Hold",
          action: "mark_as_hold",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as In-Progress",
          action: "mark_as_inprogress",
          icon: "mdi-check-all",
        },
      ],
    };
  },
  components: {
    draggable,
    CustomerDialog,
    CustomerPropertyDialog,
    /*   PageHeaderCount, */
    Barcode,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },

  methods: {
    exportVisitTimesheet() {
      const { query } = this.$route;

      let downloadURL = process.env.VUE_APP_API_URL + "visit-timesheet/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      if (query) {
        this.lodash.map(query, (row, index) => {
          if (row) {
            downloadURL.searchParams.append(`${index}`, row);
          }
        });
      }
      if (this.dates && this.dates[0] && this.dates[1]) {
        if (this.dates && this.dates[0]) {
          downloadURL.searchParams.append("daterange[0]", this.dates[0]);
        }
        if (this.dates && this.dates[1]) {
          downloadURL.searchParams.append("daterange[1]", this.dates[1]);
        }
      }
      if (this.filter_engineer) {
        downloadURL.searchParams.append(
          "filter_engineer",
          this.filter_engineer
        );
      }

      window.open(downloadURL, "_blank");
    },
    totalAmount(data) {
      let result = this.lodash.reduce(
        data,
        (acc, row) => {
          acc =
            this.lodash.toNumber(acc) +
            this.lodash.toNumber(row.totalTimeSeconds);
          return acc;
        },
        0
      );
      const hours = Math.floor(result / 3600);
      const minutes = Math.floor((result % 3600) / 60);
      const remainingSeconds = result % 60;
      let totalTime = `${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds`;
      return totalTime;
    },
    formatTimeCustom(date) {
      if (date) {
        let dateFormated = moment(date).format("hh:mm:ss A");
        return dateFormated;
      } else {
        return "--";
      }
    },
    formatDateCustom(date) {
      if (date) {
        let dateFormated = moment(date).format("dddd, MMM Do YYYY");
        return dateFormated;
      } else {
        return "--";
      }
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "engineer":
          _this.filter_engineer = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getRows();
    },

    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Visit Timesheet",
      },
    ]);
    this.getEngineerList();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
<style scoped>
.main-listing-page {
  border-top: 0 !important;
}
</style>
